const giftlyMasks = import.meta.glob('./*.svg', { import: 'default', query: '?raw', eager: true })

export function maskFromName (maskName: string) {
  return giftlyMasks[`./${maskName}.svg`] || Object.values(giftlyMasks)[0]
}

export function nextMaskFor (maskName?: string, shift: number = 1) {
  const maskNames = Object.keys(giftlyMasks).map(path => path.replace(/\.\/(.*?)\.svg/, '$1'))
  const index = maskName ? maskNames.indexOf(maskName) : 0
  return maskNames[(index + shift + maskNames.length) % maskNames.length]
}

<script lang="ts" setup>import { computed as _computed, ref as _ref } from 'vue';

import type GiftTemplate from '@consumer/types/GiftTemplate'
import { useListeners } from '@shared/vue'
import { truncate } from '@consumer/helpers/string'
import { getTagline, generateDefaultTagline, defaultV3, v3WithImageBg } from '@consumer/logic/giftly'
import { giftlyBackgroundUrl } from '@consumer/utils/url'
import { faPaintBrush } from '@fortawesome/pro-regular-svg-icons'
import { maskFromName } from '@consumer/assets/masks'

import '@consumer/styles/giftly_image.scss'

withDefaults(defineProps<{
  gift: GiftTemplate
  createMyOwn?: boolean
  inCustomizer?: boolean
}>(), {  })

const emit = defineEmits<{
  clickBackground: []
  clickItem: []
  imageLoaded: [Event]
}>()

const isClickable = useListeners(listeners => ({
  background: typeof listeners.onClickBackground === 'function',
  item: typeof listeners.onClickItem === 'function',
} as const))

const bgImageUrl = _computed(() => __props.gift.backgroundImageUrl || __props.gift.giftlyMetadata?.backgroundImageUrl)

const designName = _computed(() =>
  __props.gift.giftlyDesignName
    || __props.gift.giftlyMetadata?.designName
    || (bgImageUrl.value ? v3WithImageBg : defaultV3))

const usesUnsplashImage = _computed(() => designName.value === v3WithImageBg)

const backgroundColor = _computed(() =>
  __props.gift.giftlyMetadata?.backgroundColor
    || (designName.value === defaultV3 && '#44A4FF')
    || undefined)

const backgroundImageUrl = _computed(() => {
  if (usesUnsplashImage.value) return bgImageUrl.value
  if (backgroundColor.value) return __props.inCustomizer ? bgImageUrl.value : undefined
  return giftlyBackgroundUrl(designName.value)!
})

const tagline = _computed(() => getTagline(__props.gift) || generateDefaultTagline(__props.gift))
const maskColor = _computed(() => __props.createMyOwn ? '#F3F9FF' : __props.gift.giftlyMetadata?.maskColor)
const maskName = _computed(() => __props.gift.giftlyMetadata?.maskName || 'giftly-v3-mask-1')
const logoColor = _computed(() => {
  if (__props.createMyOwn) return '#7BBEFF80'
  return __props.gift.giftlyMetadata?.logoColor || (designName.value === defaultV3 ? '#44a4ff' : '#FFF')
})
const maskSvg = _computed(() => maskName.value && maskFromName(maskName.value))

let crossorigin = _ref<'anonymous'>()
watch(() => backgroundImageUrl.value, (backgroundImageUrl) => {
  crossorigin.value = usesUnsplashImage.value && __props.inCustomizer ? 'anonymous' : undefined
}, { immediate: true })

// eslint-disable-next-line
const transparentSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII='
</script>

<template>
  <div
    class="giftly-image-v3-wrapper giftly-image-wrapper select-none"
  >
    <div
      class="giftly-image-v3"
      :class="usesUnsplashImage && `with-${maskName}`"
      :style="{
        background: (createMyOwn && '#7bbeff33') || backgroundColor
      }"
    >
      <img
        v-if="!createMyOwn"
        :key="crossorigin"
        :src="backgroundImageUrl || transparentSrc"
        class="giftly-image-v3--background-image"
        :class="isClickable.background && 'clickable'"
        name="nc_giftly_background"
        :loading="inCustomizer ? undefined : 'lazy'"
        :crossorigin="crossorigin"
        :alt="`Background for ${tagline} Gift Card`"
        :draggable="false"
        @click="emit('clickBackground')"
        @load="emit('imageLoaded', $event)"
        @error="crossorigin = undefined"
      >

      <div
        class="giftly-image-v3--mask pointer-events-none"
        :style="{ color: maskColor || '#00407D' }"
        v-html="maskSvg"
      />

      <i class="giftly-image-v3--giftly-logo" :style="{ color: logoColor }">
        <GIcon name="new-misc-giftly-logo" color="inherit" size=""/>
      </i>

      <div
        v-if="createMyOwn"
        class="flex items-center justify-center absolute inset-0 text-primary-base"
      >
        <div
          class="bg-white shadow-md rounded-full flex items-center justify-center"
          style=" height: 80em;width: 80em;"
        >
          <V2Icon :icon="faPaintBrush" style="height: 32em; width: 32em;"/>
        </div>

        <span class="absolute font-semibold text-center" style="bottom: 2em; font-size: 18em;">
          Create your own
        </span>
      </div>
      <div v-else class="giftly-image-v3--tagline">
        <div
          class="giftly-image-v3--tagline--text"
          :class="{
            clickable: isClickable.item,
            'compact-text': tagline.length > 20,
          }"
          @click="emit('clickItem')"
        >
          {{ truncate(tagline, { length: 66 }) }}
        </div>
      </div>
    </div>
  </div>
</template>
